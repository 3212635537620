import { Stack, InputLabel, Typography } from "@mui/material";
import { t } from "i18next";
import { styleInputDefault } from "../../../../../../theme";
import InternationalPhoneNumberInput, {
  InternationalPhoneNumber,
} from "../../../../../InternationalPhoneNumberInput";

interface MemberOnboardingPhoneNumberInputProps {
  value: InternationalPhoneNumber;
}
export default function MemberOnboardingPhoneNumberInput({
  value,
}: MemberOnboardingPhoneNumberInputProps) {
  return (
    <Stack spacing={1}>
      <InputLabel>
        <Typography color="neutral.700" variant="body2" fontWeight={300}>
          {t("CollectPhoneNumber.CellphoneNumberInputLabel")}
        </Typography>
      </InputLabel>
      <InternationalPhoneNumberInput
        value={value}
        isDisabled={true}
        onChange={() => {}}
        size="small"
        inputStylesOverride={styleInputDefault}
      />
    </Stack>
  );
}
