import useGetMarketingChannelsProvinces from "@/hooks/useGetMarketingChannelsProvinces";
import useGetMarketingChannelsStores from "@/hooks/useGetMarketingChannelsStores";
import {
  MarketingChannel,
  MarketingChannelOptions,
  MarketingChannelStore,
} from "@/services/core-api-adapter";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { useGlobalStore } from "@/store";
import { styleInputDefault } from "@/theme";
import { convertPxToRem, convertUpperCaseToSentenceCase } from "@/utils";
import {
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { t } from "i18next";
import { useId, useState } from "react";

interface MemberOnboardingMarketingChannelInputProps {
  marketingChannels: MarketingChannel[];
  marketingChannelValue: MarketingChannel | null;
  otherSourceValue: string;
  onMarketingChannelSelectChange: (value: MarketingChannel) => void;
  onOtherSourceValueChange: (value: string) => void;
  onMarketingChannelStoreChange: (value: MarketingChannelStore) => void;
  marketingChannelStoreValue: MarketingChannelStore | string;
  onAgentCodeChange: (value: string) => void;
}

export default function MemberOnboardingMarketingChannelInputs({
  marketingChannels,
  marketingChannelValue,
  otherSourceValue,
  onMarketingChannelSelectChange,
  onOtherSourceValueChange,
  onMarketingChannelStoreChange,
  marketingChannelStoreValue,
  onAgentCodeChange,
}: MemberOnboardingMarketingChannelInputProps) {
  const whereDidYouHearOfUnuID = useId();
  const otherInputID = useId();
  const provinceInputID = useId();
  const storeInputID = useId();
  const agentCodeInputID = useId();
  const { state } = useGlobalStore();

  const isStoreSelectionEnabled =
    isFeatureEnabled(
      FeatureToggleIdentifier.ENABLE_COLLECT_LEAD_SOURCE_STORE_SELECTION
    ) && !state?.isThirdPartyMember;

  const [selectedProvince, setSelectedProvince] = useState<string>("");
  const [selectedMarketingChannel, setSelectedMarketingChannel] =
    useState<MarketingChannel | null>(marketingChannelValue || null);
  const [agentCodeError, setAgentCodeError] = useState<boolean>(false);

  const {
    marketingChannelsStores,
    marketingChannelsStoresFetchError,
    isMarketingChannelsStoresLoading,
  } = useGetMarketingChannelsStores({
    id: selectedMarketingChannel?.id || "",
    province: selectedProvince,
  });

  const {
    marketingChannelsProvinces,
    marketingChannelsProvincesFetchError,
    isMarketingChannelsProvincesLoading,
  } = useGetMarketingChannelsProvinces(selectedMarketingChannel?.id || "");

  const onHandleWhereDidYouHearOfUnuInput = (
    event: SelectChangeEvent<MarketingChannel>
  ) => {
    const {
      target: { value },
    } = event;

    const marketingChannel = value as MarketingChannel;
    onMarketingChannelSelectChange(marketingChannel);
    setSelectedMarketingChannel(marketingChannel);
  };

  const onHandleMarketingChannelStoreChange = (
    event: SelectChangeEvent<string>
  ) => {
    const {
      target: { value },
    } = event;

    const marketingChannelStore = value as any;
    onMarketingChannelStoreChange(marketingChannelStore);
  };

  const onHandleProvinceSelectInput = (event: SelectChangeEvent<string>) => {
    const {
      target: { value },
    } = event;

    const marketingChannelProvince = value;
    setSelectedProvince(marketingChannelProvince);
  };

  function onOtherInput(event: React.ChangeEvent<HTMLInputElement>): void {
    const { value } = event.target;
    onOtherSourceValueChange(value);
  }

  function onHandleAgentCodeChange(value: string): void {
    const agentCodeRegex = /^P\d{5}$/;

    if (!agentCodeRegex.test(value)) {
      setAgentCodeError(true);
    } else {
      setAgentCodeError(false);
    }

    onAgentCodeChange(value);
  }

  return (
    <>
      <Stack spacing={1}>
        <InputLabel htmlFor={whereDidYouHearOfUnuID}>
          <Typography color="neutral.700" variant="body2" fontWeight={300}>
            {t("WhereDidYouHearOfUnuInput.label")}
          </Typography>

          <Select
            fullWidth
            labelId={whereDidYouHearOfUnuID}
            displayEmpty
            value={marketingChannelValue || ""}
            onChange={onHandleWhereDidYouHearOfUnuInput}
            renderValue={(selected) => {
              if (!selected) {
                return <>{t("WhereDidYouHearOfUnuInput.placeholderLabel")}</>;
              }

              return t(
                `WhereDidYouHearOfUnuInput.${selected.name}.label` as any
              );
            }}
            inputProps={{
              "aria-label": t("WhereDidYouHearOfUnuInput.label"),
            }}
            MenuProps={{
              "aria-label": t("WhereDidYouHearOfUnuInput.label"),
            }}
          >
            <MenuItem disabled aria-label="Placeholder" value="">
              <>{t("WhereDidYouHearOfUnuInput.placeholderLabel")}</>
            </MenuItem>

            {marketingChannels.map(
              (marketingChannel: MarketingChannel, index) => (
                <MenuItem
                  key={marketingChannel.name + index}
                  value={marketingChannel as any}
                >
                  <ListItemText
                    primary={t(
                      `WhereDidYouHearOfUnuInput.${marketingChannel.name}.label` as any
                    )}
                  />
                </MenuItem>
              )
            )}
          </Select>
        </InputLabel>
      </Stack>

      {marketingChannelValue?.name === MarketingChannelOptions.OTHER && (
        <Stack spacing={1}>
          <InputLabel htmlFor={otherInputID}>
            <Typography color="neutral.700" variant="body2" fontWeight={300}>
              {t("OtherSelectionInput.label")}
            </Typography>
          </InputLabel>
          <TextField
            id={otherInputID}
            value={otherSourceValue}
            onInput={onOtherInput}
            inputProps={{
              autoComplete: "off",
              "aria-label": t("OtherSelectionInput.label"),
            }}
            sx={{
              input: {
                ...styleInputDefault,
                fontSize: convertPxToRem(16),
              },
            }}
            fullWidth
          />
        </Stack>
      )}

      {marketingChannelValue?.name === MarketingChannelOptions.ACKERMANS &&
        isStoreSelectionEnabled && (
          <>
            {marketingChannelsProvinces &&
              !isMarketingChannelsProvincesLoading &&
              !marketingChannelsProvincesFetchError && (
                <Stack spacing={1}>
                  <InputLabel htmlFor={provinceInputID}>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("WhereDidYouHearOfUnuInput.province.label", {
                        store: convertUpperCaseToSentenceCase(
                          marketingChannelValue?.name
                        ),
                      })}
                    </Typography>

                    <Select
                      fullWidth
                      labelId={provinceInputID}
                      displayEmpty
                      value={selectedProvince || ""}
                      onChange={onHandleProvinceSelectInput}
                      inputProps={{
                        "aria-label": t(
                          "WhereDidYouHearOfUnuInput.province.label",
                          {
                            store: convertUpperCaseToSentenceCase(
                              marketingChannelValue?.name
                            ),
                          }
                        ),
                      }}
                      MenuProps={{
                        "aria-label": t(
                          "WhereDidYouHearOfUnuInput.province.label",
                          {
                            store: convertUpperCaseToSentenceCase(
                              marketingChannelValue?.name
                            ),
                          }
                        ),
                      }}
                    >
                      <MenuItem disabled aria-label="Placeholder" value="">
                        <>{t("WhereDidYouHearOfUnuInput.placeholderLabel")}</>
                      </MenuItem>

                      {marketingChannelsProvinces &&
                        marketingChannelsProvinces.map((province, index) => (
                          <MenuItem
                            key={province + index}
                            value={province as any}
                          >
                            <ListItemText
                              primary={<Typography>{province}</Typography>}
                            />
                          </MenuItem>
                        ))}
                    </Select>
                  </InputLabel>
                </Stack>
              )}

            {marketingChannelsStores &&
              !isMarketingChannelsStoresLoading &&
              !marketingChannelsStoresFetchError && (
                <Stack spacing={1}>
                  <InputLabel htmlFor={storeInputID}>
                    <Typography
                      color="neutral.700"
                      variant="body2"
                      fontWeight={300}
                    >
                      {t("WhereDidYouHearOfUnuInput.store.label", {
                        province: selectedProvince,
                      })}
                    </Typography>

                    <Select
                      fullWidth
                      labelId={storeInputID}
                      displayEmpty
                      value={marketingChannelStoreValue as any}
                      onChange={onHandleMarketingChannelStoreChange}
                      inputProps={{
                        "aria-label": t(
                          "WhereDidYouHearOfUnuInput.store.label",
                          {
                            province: selectedProvince,
                          }
                        ),
                      }}
                      MenuProps={{
                        "aria-label": t(
                          "WhereDidYouHearOfUnuInput.store.label",
                          {
                            province: selectedProvince,
                          }
                        ),
                      }}
                    >
                      <MenuItem disabled aria-label="Placeholder" value="">
                        <>{t("WhereDidYouHearOfUnuInput.placeholderLabel")}</>
                      </MenuItem>

                      {marketingChannelsStores &&
                        marketingChannelsStores.map(
                          (
                            marketingChannelStore: MarketingChannelStore,
                            index
                          ) => (
                            <MenuItem
                              key={marketingChannelStore.id + index}
                              value={marketingChannelStore as any}
                            >
                              <ListItemText
                                primary={
                                  <Typography>
                                    {marketingChannelStore.location}
                                  </Typography>
                                }
                              />
                            </MenuItem>
                          )
                        )}
                    </Select>
                  </InputLabel>
                </Stack>
              )}

            <Stack spacing={1}>
              <InputLabel htmlFor={agentCodeInputID}>
                <Typography
                  color="neutral.700"
                  variant="body2"
                  fontWeight={300}
                >
                  {t("WhereDidYouHearOfUnuInput.agentCode.label")}
                </Typography>

                <TextField
                  id={agentCodeInputID}
                  inputProps={{
                    autoComplete: "off",
                    "aria-label": t(
                      "WhereDidYouHearOfUnuInput.agentCode.label"
                    ),
                  }}
                  onChange={(event) => {
                    const { value } = event.target;
                    onHandleAgentCodeChange(value);
                  }}
                  sx={{
                    input: {
                      ...styleInputDefault,
                      fontSize: convertPxToRem(16),
                    },
                  }}
                  fullWidth
                  error={agentCodeError}
                />
              </InputLabel>
            </Stack>
          </>
        )}
    </>
  );
}
