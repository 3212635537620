import ButtonWithAnalytics from "@/components/ButtonWithAnalytics";
import FormInputErrorList from "@/components/FormInputErrorList";
import {
  MarketingChannel,
  Status,
  MarketingChannelStore,
  SubmitUserDetailsRequest,
} from "@/services/core-api-adapter";
import { FaceIconBrandLarge } from "@/theme/icons";
import { Stack, Typography } from "@mui/material";
import { t } from "i18next";
import MemberOnboardingDateOfBirthInput from "./components/MemberOnboardingDateOfBirthInput";
import MemberOnboardingFullNameInput from "./components/MemberOnboardingFullNameInput";
import MemberOnboardingMarketingChannelInputs from "./components/MemberOnboardingMarketingChannelInputs";
import MemberOnboardingPhoneNumberInput from "./components/MemberOnboardingPhoneNumberInput";
import MemberOnboardingSexAtBirthInput from "./components/MemberOnboardingSexAtBirthInput";
import { useEffect, useState } from "react";
import { InternationalPhoneNumber } from "@/components/InternationalPhoneNumberInput";
import useGetMarketingChannels from "@/hooks/useGetMarketingChannels";
import {
  FeatureToggleIdentifier,
  isFeatureEnabled,
} from "@/services/feature-toggle-adapter";
import { MarketingChannelOptions } from "../../../../services/core-api-adapter";
import { useGlobalStore } from "@/store";

export interface UserOnboardingDetails {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  sexAtBirth: string;
  phoneNumber: InternationalPhoneNumber | null;
  otherSourceValue: string;
  marketingChannel: MarketingChannel | undefined;
  marketingChannelStore: MarketingChannelStore | undefined;
  agentCode: string | undefined;
}

interface MemberOnboardingFormProps {
  onFormSubmit: (data: SubmitUserDetailsRequest) => void;
  isFormError: boolean;
  shouldCollectAdditionalDetails: boolean;
  data?: UserOnboardingDetails;
}

export default function MemberOnboardingForm({
  onFormSubmit,
  isFormError,
  shouldCollectAdditionalDetails,
  data,
}: MemberOnboardingFormProps) {
  const { state } = useGlobalStore();

  const isThirdPartyMember =
    state?.isThirdPartyMember &&
    isFeatureEnabled(FeatureToggleIdentifier.ENABLE_THIRD_PARTY_AUTHENTICATION);

  const [submitButtonEnabled, setSubmitButtonEnabled] =
    useState<boolean>(false);

  const isStoreSelectionEnabled =
    isFeatureEnabled(
      FeatureToggleIdentifier.ENABLE_COLLECT_LEAD_SOURCE_STORE_SELECTION
    ) && !isThirdPartyMember;

  const {
    marketingChannels,
    marketingChannelsFetchError,
    isMarketingChannelsLoading,
  } = useGetMarketingChannels({
    status: Status.ACTIVE,
  });

  const [userOnboardingDetails, setUserOnboardingDetails] =
    useState<UserOnboardingDetails>(
      data || {
        firstName: "",
        lastName: "",
        dateOfBirth: "",
        sexAtBirth: "",
        phoneNumber: null,
        otherSourceValue: "",
        marketingChannel: undefined,
        marketingChannelStore: undefined,
        agentCode: undefined,
      }
    );

  function isValidSexAtBirthInput(sexAtBirth: string) {
    if (sexAtBirth !== "Select") {
      return sexAtBirth;
    }
    return "";
  }

  function isFormValid(): boolean {
    const {
      phoneNumber,
      firstName,
      lastName,
      sexAtBirth,
      dateOfBirth,
      marketingChannel,
      marketingChannelStore,
      agentCode,
    } = userOnboardingDetails;

    const isPhoneNumberValid = phoneNumber?.globalSubscriberNumber || "";
    const isSexAtBirthValid = isValidSexAtBirthInput(sexAtBirth);

    let isMarketingChannelValid = false;

    if (isThirdPartyMember === true) {
      isMarketingChannelValid = true;
    } else {
      if (
        marketingChannel?.name === MarketingChannelOptions.ACKERMANS &&
        isStoreSelectionEnabled
      ) {
        isMarketingChannelValid =
          Boolean(marketingChannel?.name) && Boolean(marketingChannelStore?.id);
      } else {
        isMarketingChannelValid = Boolean(marketingChannel?.id);
      }
    }

    let isAgentCodeValid = true;
    if (agentCode && isStoreSelectionEnabled) {
      const agentCodeRegex = /^P\d{5}$/;
      if (!agentCodeRegex.test(agentCode)) {
        isAgentCodeValid = false;
      }
    }

    if (
      isPhoneNumberValid &&
      firstName &&
      lastName &&
      (!shouldCollectAdditionalDetails || (isSexAtBirthValid && dateOfBirth)) &&
      isMarketingChannelValid &&
      isAgentCodeValid
    ) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (userOnboardingDetails) {
      setSubmitButtonEnabled(isFormValid());
    }
  }, [
    isStoreSelectionEnabled,
    shouldCollectAdditionalDetails,
    submitButtonEnabled,
    userOnboardingDetails,
  ]);

  function buildSubmitUserDetailsRequest(): SubmitUserDetailsRequest {
    const {
      firstName,
      lastName,
      dateOfBirth,
      sexAtBirth,
      marketingChannel,
      otherSourceValue,
    } = userOnboardingDetails;

    const otherMarketingChannelId = marketingChannels?.find(
      (channel) => channel.name === MarketingChannelOptions.OTHER
    )?.id;

    let metaData = {};

    if (marketingChannel?.name === MarketingChannelOptions.OTHER) {
      metaData = {
        marketingChannelDescription: otherSourceValue || null,
      };
    }

    if (isThirdPartyMember) {
      metaData = {
        marketingChannelDescription: "Church Of Uganda",
      };
    }

    if (isStoreSelectionEnabled) {
      if (marketingChannel?.name === MarketingChannelOptions.ACKERMANS) {
        metaData = {
          marketingChannelLocationId:
            userOnboardingDetails?.marketingChannelStore?.id,
          agentCode: userOnboardingDetails?.agentCode || undefined,
        };
      }
    }

    const formData: SubmitUserDetailsRequest = {
      firstName,
      lastName,
      dateOfBirth: shouldCollectAdditionalDetails ? dateOfBirth : undefined,
      sexAtBirth: shouldCollectAdditionalDetails ? sexAtBirth : undefined,
      marketingChannel: {
        id: isThirdPartyMember
          ? (otherMarketingChannelId ?? "")
          : (userOnboardingDetails?.marketingChannel?.id ?? ""),
        metaData: metaData,
      },
    };

    return formData;
  }

  async function onSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    onFormSubmit(buildSubmitUserDetailsRequest());
  }

  return (
    <form onSubmit={onSubmit}>
      <Stack spacing={2} justifyContent="flex-end" sx={{ pb: 2 }}>
        <Stack spacing={2} sx={{ pb: 5 }}>
          <FaceIconBrandLarge />
          <Stack spacing={2}>
            <Typography variant="h3">
              {t("CollectRetailUserDetails.title")}
            </Typography>
          </Stack>
        </Stack>

        {userOnboardingDetails.phoneNumber && (
          <MemberOnboardingPhoneNumberInput
            value={userOnboardingDetails.phoneNumber}
          />
        )}

        <MemberOnboardingFullNameInput
          firstNameValue={userOnboardingDetails.firstName}
          lastNameValue={userOnboardingDetails.lastName}
          onFirstNameChange={(value: string) =>
            setUserOnboardingDetails({
              ...userOnboardingDetails,
              firstName: value,
            })
          }
          onLastNameChange={(value: string) =>
            setUserOnboardingDetails({
              ...userOnboardingDetails,
              lastName: value,
            })
          }
        />

        {!marketingChannelsFetchError &&
          !isMarketingChannelsLoading &&
          marketingChannels &&
          marketingChannels?.length > 0 &&
          !isThirdPartyMember && (
            <MemberOnboardingMarketingChannelInputs
              marketingChannels={marketingChannels}
              otherSourceValue={userOnboardingDetails?.otherSourceValue}
              marketingChannelValue={
                userOnboardingDetails.marketingChannel || null
              }
              onOtherSourceValueChange={(value: string) =>
                setUserOnboardingDetails({
                  ...userOnboardingDetails,
                  otherSourceValue: value,
                })
              }
              onMarketingChannelSelectChange={(value: MarketingChannel) => {
                setUserOnboardingDetails({
                  ...userOnboardingDetails,
                  marketingChannel: value,
                  ...(value.name === MarketingChannelOptions.OTHER && {
                    otherSourceValue: "",
                  }),
                  marketingChannelStore: undefined,
                  agentCode: "",
                });
              }}
              onMarketingChannelStoreChange={(value: MarketingChannelStore) => {
                setUserOnboardingDetails({
                  ...userOnboardingDetails,
                  marketingChannelStore: value,
                });
              }}
              marketingChannelStoreValue={
                userOnboardingDetails?.marketingChannelStore || ""
              }
              onAgentCodeChange={(value: string) => {
                setUserOnboardingDetails({
                  ...userOnboardingDetails,
                  agentCode: value,
                });
              }}
            />
          )}

        {shouldCollectAdditionalDetails && (
          <>
            <MemberOnboardingDateOfBirthInput
              value={userOnboardingDetails.dateOfBirth}
              onChange={(value: string) =>
                setUserOnboardingDetails({
                  ...userOnboardingDetails,
                  dateOfBirth: value,
                })
              }
            />

            <MemberOnboardingSexAtBirthInput
              value={userOnboardingDetails.sexAtBirth || "Select"}
              onChange={(value: string) => {
                setUserOnboardingDetails({
                  ...userOnboardingDetails,
                  sexAtBirth: value,
                });
              }}
            />
          </>
        )}

        {isFormError && (
          <FormInputErrorList errors={[t("common.somethingWentWrong")]} />
        )}

        <ButtonWithAnalytics
          page="CollectMemberOnboardingDetails"
          text={t("common.nextButton")}
          intent="navigational"
          type="submit"
          color="primary"
          disabled={!submitButtonEnabled}
          aria-label={t("common.nextButton")}
          fullWidth
        >
          {t("common.nextButton")}
        </ButtonWithAnalytics>
      </Stack>
    </form>
  );
}
